import { Flex, FlexProps, Text } from "@chakra-ui/react";
import { useWindowSiteStore } from "@store/windowSiteStore";
import { scale } from "@utils/helpers";
import { useEffect, useState } from "react";
import SubscribeForm from "../forms/SubscribeForm";
import { PaddedOnMobile } from "../layout";
import { motion } from "framer-motion";

const MotionFlex = motion(Flex);

type SubscribeSectionProps = {
    contentProps?: FlexProps;
};

export const SubscribeSection = ({ contentProps, ...props }: SubscribeSectionProps & FlexProps) => {
    const isDesktop = useWindowSiteStore((state) => state.isDesktop);
    const windowWidth = useWindowSiteStore((state) => state.windowWidth);

    const minSize = isDesktop ? 960 : 375;
    const maxSize = isDesktop ? 1200 : 960;

    const slideUpAnimation = (delay = 0, once = true) => ({
        initial: {
            translateY: "50px",
            opacity: 0,
        },
        whileInView: {
            translateY: "0px",
            opacity: 1,
        },
        transition: {
            duration: 0.8,
            ease: "easeOut",
            delay: delay,
        },
        viewport: {
            once: once,
        },
    });

    return (
        <>
            <PaddedOnMobile>
                <Flex direction="column" gap={6} {...props} textAlign="center">
                    <Flex direction="column" maxW="450px" gap={2} {...contentProps}>
                        <Text
                            fontFamily="regular"
                            fontSize={scale(windowWidth, minSize, maxSize, 24, 48)}
                            lineHeight="110%"
                        >
                            Join our community
                        </Text>
                        <Text
                            fontSize={scale(windowWidth, minSize, maxSize, 18, 24)}
                            fontWeight="light"
                        >
                            Get the latest in NFT deals and insights delivered straight to you
                            inbox.
                        </Text>
                    </Flex>
                    <MotionFlex w="100%" {...slideUpAnimation()}>
                        <SubscribeForm
                            // height={wider ? "50px" : smWidth ? "30px" : "40px"}
                            inputFontSize="16px"
                        />
                    </MotionFlex>
                </Flex>
            </PaddedOnMobile>
        </>
    );
};

export default SubscribeSection;
