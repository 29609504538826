import { Box, Button, Icon, Link, Spacer, Text, VStack } from "@chakra-ui/react";
import { Children, ReactNode, useEffect, useState } from "react";
import { IconType } from "react-icons";

type SocialCardProps = {
    icon: IconType;
    name: string;
    message?: string;
    buttonLink?: string;
    buttonLabel?: string;
    children?: ReactNode;
};

export const SocialCard = ({ icon, name, message, buttonLink, buttonLabel, children }: SocialCardProps) => {
    return (
        <>
            <Box
                position="relative"
                h="220px"
                w="250px"
                pt={10}
                px={4}
                pb={4}
                bgColor="arsenic"
                border="2px solid"
                borderColor="blackCoral"
                rounded={10}
                lineHeight={1.1}
            >
                <Box position="absolute" top="-34px" left="20px">
                    <Icon boxSize={16} as={icon} zIndex={1} />
                </Box>

                <VStack h="100%" gap={0} justifyContent="start" alignItems="start">
                    <Text fontFamily="regular" fontSize={24}>
                        {name}
                    </Text>
                    <Text fontSize={18} fontWeight="light" color="lavenderGray">
                        {message}
                    </Text>
                    <Spacer />
                    {buttonLink && (
                        <Link
                            href={buttonLink}
                            isExternal
                            style={{ textDecoration: "none" }}
                            role="group"
                            w="100%"
                        >
                            <Button
                                variant="white"
                                rounded={30}
                                py="12px"
                                w="60%"
                                fontSize={15}
                                fontStyle="site.cardFooterText"
                            >
                                {buttonLabel}
                            </Button>
                        </Link>
                    )}
                    {children}
                </VStack>
            </Box>
        </>
    );
};

export default SocialCard;
