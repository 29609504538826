import { Box, Flex, Grid, GridItem, Icon, Link, Spacer, Text, VStack } from "@chakra-ui/react";
import { useWindowSiteStore } from "@store/windowSiteStore";
import { scale } from "@utils/helpers";
import { Fragment, useEffect, useState } from "react";
import NextLink from "next/link";
import { PaddedOnMobile } from "../layout";
import GradientButton from "@components/ui/GradientButton";
import { FiCheck } from "react-icons/fi";
import { motion } from "framer-motion";
import { SignInModalState, useBasicStore } from "@store/basicStore";
import useUser from "@hooks/useUser";
import { MemoSignedInUserAvatar } from "@components/auth/SignedInUserAvatar";
import { useRouter } from "next/router";

const MotionBox = motion(Box);
const MotionFlex = motion(Flex);

type PricingSectionProps = {};

const CHECK_POINTS = [
    "Instant Rarity & Rankings",
    "Instant Price Estimates",
    "Hottest Deals at a Glance",
    "Lowest Listed Marketplaces",
    "Portfolio Gallery & Net worth",
    "And Way More",
];

export const PricingSection = ({}: PricingSectionProps) => {
    const router = useRouter();
    const { user, status } = useUser();
    const isDesktop = useWindowSiteStore((state) => state.isDesktop);
    const windowWidth = useWindowSiteStore((state) => state.windowWidth);
    const openSignInModal = (state: SignInModalState) => {
        useBasicStore.setState((oldState) => {
            return {
                signInModalState: state,
            };
        });
    };

    const hanleOnSignUp = () => {
        if (status !== "authenticated") {
            openSignInModal(SignInModalState.SignUp);
        } else {
            router.push("/user/me", "/user/me");
        }
    };

    const minSize = isDesktop ? 960 : 375;
    const maxSize = isDesktop ? 1200 : 960;

    const scaleAnimation = (delay = 0, once = true) => ({
        initial: {
            scale: 0.8,
            opacity: 0,
        },
        whileInView: {
            scale: 1,
            opacity: 1,
        },
        transition: {
            duration: 0.5,
            ease: "easeOut",
            delay: delay,
        },
        viewport: {
            once: once,
        },
    });

    const SignedInLabel = () => {
        if (status === "unauthenticated") return null;

        return (
            <Flex
                w="100%"
                justify="center"
                align="center"
                fontSize={scale(windowWidth, minSize, maxSize, 22, windowWidth > 450 ? 28 : 24)}
                py="4px"
            >
                <Flex
                    gap={3}
                    align="center"
                    justify="center"
                >
                    <NextLink shallow href="/user/me" passHref>
                        <Link href="/user/me">
                            <MemoSignedInUserAvatar
                                name={user?.name || ""}
                                image={user?.image || ""}
                                buttonSize={"36px"}
                                isMobile={false}
                            />
                        </Link>
                    </NextLink>
                    <Box textAlign="center">
                        You’re all set!
                    </Box>
                </Flex>
            </Flex>
        );
    };

    if (isDesktop) {
        return (
            <>
                <Box pos="relative" minW="100vw" bgColor="spaceCadetCardAlpha.600" py="150px">
                    <PaddedOnMobile>
                        <Grid templateColumns="1fr auto" gap={10}>
                            <GridItem px={8}>
                                <VStack h="100%">
                                    <Flex
                                        direction="column"
                                        justify="center"
                                        align="center"
                                        textAlign="center"
                                        gap={3}
                                    >
                                        <Box
                                            fontFamily="regular"
                                            fontSize={scale(windowWidth, minSize, maxSize, 32, 48)}
                                            lineHeight="110%"
                                        >
                                            Get started for free
                                        </Box>
                                        <Box
                                            pt={2}
                                            fontSize={scale(windowWidth, minSize, maxSize, 18, 24)}
                                            fontWeight="light"
                                        >
                                            <Text as="span" whiteSpace="nowrap">
                                                Join 5,000+ NFT enthusiasts today.
                                            </Text>
                                            <br />
                                            <Text as="span">
                                                For full details on our pricing options, click&nbsp;
                                                <NextLink shallow href="/pricing" passHref>
                                                    <Link
                                                        href="/pricing"
                                                        fontWeight="semibold"
                                                        color="sorbet"
                                                    >
                                                        here
                                                    </Link>
                                                </NextLink>
                                            </Text>
                                            .
                                        </Box>
                                    </Flex>
                                    <Spacer />
                                    <MotionBox w="80%" {...scaleAnimation()}>
                                        {status != "authenticated" ? (
                                            <GradientButton
                                                onClick={hanleOnSignUp}
                                                label="Sign Up for Free"
                                                buttonStyle={{ px: 2 }}
                                            />
                                        ) : (
                                            SignedInLabel()
                                        )}
                                    </MotionBox>
                                </VStack>
                            </GridItem>

                            <GridItem>
                                <Grid
                                    templateColumns="auto 1fr"
                                    templateRows={`repeat(1fr, ${CHECK_POINTS.length})`}
                                    columnGap={4}
                                    rowGap={2}
                                >
                                    {CHECK_POINTS.map((point, index) => {
                                        return (
                                            <Fragment key={`cp-check-${index}`}>
                                                <GridItem>
                                                    <MotionFlex
                                                        h="100%"
                                                        justify="center"
                                                        align="center"
                                                        {...scaleAnimation(0.2 * index)}
                                                    >
                                                        <Icon
                                                            as={FiCheck}
                                                            fontSize={scale(
                                                                windowWidth,
                                                                minSize,
                                                                maxSize,
                                                                24,
                                                                28,
                                                            )}
                                                        />
                                                    </MotionFlex>
                                                </GridItem>
                                                <GridItem>
                                                    <Flex
                                                        h="100%"
                                                        justify="start"
                                                        align="center"
                                                        fontSize={scale(
                                                            windowWidth,
                                                            minSize,
                                                            maxSize,
                                                            18,
                                                            24,
                                                        )}
                                                        fontWeight="light"
                                                    >
                                                        {point}
                                                    </Flex>
                                                </GridItem>
                                            </Fragment>
                                        );
                                    })}
                                </Grid>
                            </GridItem>
                        </Grid>
                    </PaddedOnMobile>
                </Box>
            </>
        );
    }

    // Mobile
    return (
        <>
            <Box pos="relative" minW="100vw" bgColor="spaceCadetCardAlpha.600" py="100px">
                <PaddedOnMobile>
                    <VStack gap={8}>
                        <Flex
                            direction="column"
                            justify="center"
                            align="center"
                            textAlign="center"
                            gap={3}
                        >
                            <Box
                                fontFamily="regular"
                                fontSize={scale(windowWidth, minSize, maxSize, 32, 48)}
                                lineHeight="110%"
                            >
                                Get started for free
                            </Box>
                            <Box
                                pt={2}
                                fontSize={scale(windowWidth, minSize, maxSize, 18, 24)}
                                fontWeight="light"
                                wordBreak="break-word"
                            >
                                <Text as="span" whiteSpace="nowrap">
                                    Join 5,000+ NFT enthusiasts today.
                                </Text>
                                <br />
                                <Text as="span">
                                    For full details on our pricing options, click&nbsp;
                                    <NextLink shallow href="/pricing" passHref>
                                        <Link href="/pricing" fontWeight="semibold" color="sorbet">
                                            here
                                        </Link>
                                    </NextLink>
                                </Text>
                                .
                            </Box>
                        </Flex>
                        <Grid
                            templateColumns="auto 1fr"
                            templateRows={`repeat(1fr, ${CHECK_POINTS.length})`}
                            columnGap={4}
                            rowGap={2}
                        >
                            {CHECK_POINTS.map((point, index) => {
                                return (
                                    <Fragment key={`cp-check-${index}`}>
                                        <GridItem>
                                            <MotionFlex
                                                h="100%"
                                                justify="center"
                                                align="center"
                                                {...scaleAnimation(0.2 * index)}
                                            >
                                                <Icon
                                                    as={FiCheck}
                                                    fontSize={scale(
                                                        windowWidth,
                                                        minSize,
                                                        maxSize,
                                                        24,
                                                        30,
                                                    )}
                                                />
                                            </MotionFlex>
                                        </GridItem>
                                        <GridItem>
                                            <Flex
                                                h="100%"
                                                justify="start"
                                                align="center"
                                                fontSize={scale(
                                                    windowWidth,
                                                    minSize,
                                                    maxSize,
                                                    18,
                                                    24,
                                                )}
                                                fontWeight="light"
                                            >
                                                {point}
                                            </Flex>
                                        </GridItem>
                                    </Fragment>
                                );
                            })}
                        </Grid>
                        <MotionBox w="80%" {...scaleAnimation()}>
                            {status != "authenticated" ? (
                                <GradientButton
                                    onClick={hanleOnSignUp}
                                    label="Sign Up for Free"
                                    buttonStyle={{ px: 2 }}
                                />
                            ) : (
                                SignedInLabel()
                            )}
                        </MotionBox>
                    </VStack>
                </PaddedOnMobile>
            </Box>
        </>
    );
};

export default PricingSection;
