import { setCompletedQuickTour } from "@store/persistedStore";
import TutorialsModal from "./TutorialsModal";
import GradientButton from "@components/ui/GradientButton";
import { FlexProps } from "@chakra-ui/react";
import { useState } from "react";

type TutorialsGradientButtonProps = { buttonStyle?: FlexProps };

export const TutorialsGradientButton = ({ buttonStyle }: TutorialsGradientButtonProps) => {
    const [shouldRenderModal, setShouldRenderModal] = useState<boolean>(false);

    const handleOnTutorialsClick = () => {
        setShouldRenderModal(true);
    };

    const onboardCompleted = () => {
        setCompletedQuickTour(Date.now());
        setShouldRenderModal(false);
    };

    const onTutorialsCloseComplete = () => {
        setCompletedQuickTour(Date.now());
        setShouldRenderModal(false);
    };

    return (
        <>
            <GradientButton
                onClick={handleOnTutorialsClick}
                label="More tutorials"
                buttonStyle={buttonStyle}
            />
            {shouldRenderModal && (
                <TutorialsModal
                    isOpen={shouldRenderModal}
                    onClose={onboardCompleted}
                    onCloseComplete={onTutorialsCloseComplete}
                />
            )}
        </>
    );
};

export default TutorialsGradientButton;
