import {
    Box,
    Icon,
    PopoverArrowProps,
    PopoverContentProps,
    PopoverProps,
    Portal,
    Text,
    Tooltip,
} from "@chakra-ui/react";
import { MdInfo } from "react-icons/md";
// import appConfig from "/appConfig";
import { ClickHoverPopover, ClickHoverPopoverTrigger } from "./ClickHoverPopover";
import { PopoverContent, PopoverArrow, PopoverBody } from "@chakra-ui/react";
import { IconType } from "react-icons";

type InfoTooltipProps = {
    label: any;
    icon?: IconType;
    iconColor?: string;
    iconSize?: string | number;
    iconProps?: any;
    popoverContentProps?: PopoverContentProps;
    popoverArrowProps?: PopoverArrowProps;
    noArrow?: boolean;
    usePortal?: boolean;
};

export const InfoTooltip = ({
    label,
    icon = MdInfo,
    iconColor = "lavenderGray",
    iconSize,
    iconProps,
    popoverContentProps,
    popoverArrowProps,
    noArrow = false,
    usePortal = false,
    ...props
}: InfoTooltipProps & PopoverProps) => {
    const InfoContent = () => {
        return (
            <>
                <PopoverContent
                    zIndex="tooltip"
                    bgColor="blue.darker"
                    color="lavenderGray"
                    fontSize={["sm", "sm", "md"]}
                    fontWeight={100}
                    p="5px"
                    width="auto"
                    maxW={["80vw", "80vw", "500px"]}
                    whiteSpace="normal"
                    border="1px solid white"
                    borderColor="lavenderGray"
                    _focus={{ border: "1px solid white", borderColor: "lavenderGray" }}
                    onClick={(e) => e.stopPropagation()}
                    cursor="auto"
                    {...popoverContentProps}
                >
                    {!noArrow && (
                        <PopoverArrow
                            bgColor="blue.darker"
                            boxShadow="-1px -1px 1px 0 #BBBBD1 !important"
                            {...popoverArrowProps}
                        />
                    )}
                    <PopoverBody textTransform="none" fontStyle="unset" fontWeight="normal">
                        <Box as="span" fontWeight={100}>
                            {label}
                        </Box>
                    </PopoverBody>
                </PopoverContent>
            </>
        );
    };

    const InfoContentWithPortal = () => {
        return (
            <>
                <Portal>
                    <InfoContent />
                </Portal>
            </>
        );
    };

    return (
        <>
            <ClickHoverPopover {...props}>
                <ClickHoverPopoverTrigger m="0" p="0" display="flex" align="center">
                    <Icon
                        as={icon}
                        fontSize={iconSize != null ? iconSize : "unset"}
                        color={iconColor}
                        m="0"
                        {...iconProps}
                    />
                </ClickHoverPopoverTrigger>
                {usePortal ? <InfoContentWithPortal /> : <InfoContent />}
            </ClickHoverPopover>
        </>
    );
};
