import { Box, Button, Flex, Link, Text } from "@chakra-ui/react";
import NextLink from "next/link";

const GetListedCard = ({ isDesktop = true, small = false }) => {
    return (
        <>
            <NextLink shallow href="/getlisted" passHref>
                <Link w="100%" _hover={{ textDecor: "none" }}>
                    <Box
                        textAlign="left"
                        h="100%"
                        bgColor="arsenic"
                        border="2px solid #62677A"
                        rounded={10}
                        boxShadow="1px 5px 8px 0px rgba(0,0,0,0.25)"
                        transition="all .25s ease-out"
                        _hover={{
                            bgColor: "arsenic",
                            boxShadow: "3px 10px 17px 2px rgba(0,0,0,0.65)",
                            transform: "translateY(-3px)",
                        }}
                        role="group"
                    >
                        <Flex
                            w="100%"
                            h="100%"
                            direction="column"
                            px={small ? 4 : 8}
                            py={small ? 2 : 5}
                            justifyContent="space-between"
                            alignItems={isDesktop ? "start" : "center"}
                            gap={isDesktop ? undefined : 8}
                        >
                            <Box textStyle="site.header" textAlign="center">
                                Project spotlight
                            </Box>

                            <Flex
                                direction="column"
                                gap={small ? "18px" : 4}
                                w="100%"
                                alignItems={isDesktop ? "start" : "center"}
                            >
                                <Box textAlign={isDesktop ? "left" : "center"}>
                                    <Text
                                        textStyle="site.gray.text"
                                        fontSize={!isDesktop || small ? "20px" : "24px"}
                                    >
                                        Need your collection rated?
                                    </Text>
                                    <Text
                                        textStyle="site.text"
                                        fontSize={!isDesktop || small ? "20px" : "24px"}
                                    >
                                        Join our Beyond Rarity community!
                                    </Text>
                                </Box>
                                <Box w={200}>
                                    <Button
                                        w="100%"
                                        textStyle="site.siteText"
                                        variant="white"
                                        onClick={console.log}
                                        py={small ? "15px" : "20px"}
                                        _groupHover={{ bgColor: "lavenderGray" }}
                                    >
                                        <Text
                                            textStyle="site.buttonText"
                                            fontSize={!isDesktop || small ? "18px" : "20px"}
                                        >
                                            Find out more
                                        </Text>
                                    </Button>
                                </Box>
                            </Flex>
                        </Flex>
                    </Box>
                </Link>
            </NextLink>
        </>
    );
};

export default GetListedCard;
