import { Box, Grid, GridItem, Image, VStack } from "@chakra-ui/react";
import { useWindowSiteStore } from "@store/windowSiteStore";
import { useVirtualizer } from "@tanstack/react-virtual";
import { scale } from "@utils/helpers";
import { useEffect, useRef, useState } from "react";
import { PaddedOnMobile } from "../layout";
import { motion, useInView } from "framer-motion";

const MotionImage = motion(Image);

type TestimonialsSectionProps = {};

const DesktopTestimonials = () => {
    const windowWidth = useWindowSiteStore((state) => state.windowWidth);
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    const minSize = 960;
    const maxSize = 1200;

    const scalePopAnimation = (delay = 0, once = true) => ({
        scale: isInView ? 1 : 0,
        opacity: isInView ? 1 : 0,
        style: { transition: `all .2s linear ${delay}s` },
    });

    return (
        <>
            <PaddedOnMobile>
                <VStack w="100%">
                    <VStack gap={0} textAlign="center">
                        <Box
                            fontFamily="regular"
                            fontSize={scale(windowWidth, minSize, maxSize, 36, 48)}
                        >
                            Kind Words
                        </Box>
                        <Box
                            fontSize={scale(windowWidth, minSize, maxSize, 18, 24)}
                            fontWeight="light"
                        >
                            From our Beyond Rarity community
                        </Box>
                    </VStack>
                    <Grid ref={ref} w="100%" pt={8} templateColumns="1fr 1fr 1fr" gap={4}>
                        <GridItem>
                            <VStack gap={4}>
                                {new Array(4).fill(null).map((value, index) => {
                                    return (
                                        <MotionImage
                                            key={`t-a-${index + 1}`}
                                            src={`/images/testimonials/testimonials-row${
                                                index + 1
                                            }-A.png`}
                                            alt={`testimonial a-${index + 1}`}
                                            {...scalePopAnimation(Math.random() + 0.2)}
                                        />
                                    );
                                })}
                            </VStack>
                        </GridItem>

                        <GridItem>
                            <VStack gap={4}>
                                {new Array(4).fill(null).map((value, index) => {
                                    return (
                                        <Image
                                            key={`t-b-${index + 1}`}
                                            src={`/images/testimonials/testimonials-row${
                                                index + 1
                                            }-B.png`}
                                            alt={`testimonial a-${index + 1}`}
                                            {...scalePopAnimation(Math.random() + 0.2)}
                                        />
                                    );
                                })}
                            </VStack>
                        </GridItem>

                        <GridItem>
                            <VStack gap={4}>
                                {new Array(4).fill(null).map((value, index) => {
                                    return (
                                        <Image
                                            key={`t-c-${index + 1}`}
                                            src={`/images/testimonials/testimonials-row${
                                                index + 1
                                            }-C.png`}
                                            alt={`testimonial a-${index + 1}`}
                                            {...scalePopAnimation(Math.random() + 0.2)}
                                        />
                                    );
                                })}
                            </VStack>
                        </GridItem>
                    </Grid>
                </VStack>
            </PaddedOnMobile>
        </>
    );
};

export const TestimonialsSection = ({}: TestimonialsSectionProps) => {
    const isDesktop = useWindowSiteStore((state) => state.isDesktop);
    const windowWidth = useWindowSiteStore((state) => state.windowWidth);
    const [mobileSlideWidth, setMobileSlideWidth] = useState(windowWidth > 600 ? 450 : 300);
    const initialScroll = useRef(false);

    const mobileList = new Array(12).fill(null);
    for (let i = 0; i < 3; i++) {
        const column = i == 0 ? "A" : i == 1 ? "B" : "C";

        for (let j = 0; j < 4; j++) {
            const index = i * 4 + j;

            mobileList[i * 4 + j] = (
                <>
                    <Image
                        // key={`t-${column}-${index + 1}`}
                        src={`/images/testimonials/testimonials-row${j + 1}-${column}.png`}
                        alt={`testimonial ${column}-${j + 1}`}
                    />
                </>
            );
        }
    }

    const scrollRef = useRef<HTMLDivElement>();
    const rowVirtualizer = useVirtualizer({
        overscan: 8,
        horizontal: true,
        count: mobileList.length,
        getScrollElement: () => scrollRef.current,
        estimateSize: () => mobileSlideWidth,
        paddingStart: 20,
        paddingEnd: 20,
    });

    const minSize = isDesktop ? 960 : 375;
    const maxSize = isDesktop ? 1200 : 960;

    useEffect(() => {
        rowVirtualizer?.measure();
        setMobileSlideWidth(windowWidth > 600 ? 450 : 300);

        if (initialScroll.current == false && windowWidth) {
            const indexOfCard = 1;
            const wholeSlidesFitWindow = Math.floor(windowWidth / mobileSlideWidth);
            const offset =
                20 +
                mobileSlideWidth * indexOfCard -
                (windowWidth - wholeSlidesFitWindow * mobileSlideWidth) / 2;
            // console.debug("offset: ", offset);
            rowVirtualizer.scrollToOffset(offset, { behavior: "auto" });
            initialScroll.current = true;
        }
    }, [windowWidth]);

    if (isDesktop) {
        return <DesktopTestimonials />;
    }

    // Mobile
    return (
        <>
            <VStack w="100%" gap={8} textAlign="center">
                <PaddedOnMobile>
                    <VStack gap={0}>
                        <Box
                            fontFamily="regular"
                            fontSize={scale(windowWidth, minSize, maxSize, 36, 48)}
                        >
                            Kind Words
                        </Box>
                        <Box
                            fontSize={scale(windowWidth, minSize, maxSize, 18, 24)}
                            fontWeight="light"
                        >
                            From our Beyond Rarity community
                        </Box>
                    </VStack>
                </PaddedOnMobile>
                <Box
                    ref={scrollRef}
                    position="relative"
                    w="100%"
                    height={windowWidth > 600 ? 300 : 200}
                    overflowX="auto"
                    className="no-scrollbars"
                >
                    <div
                        style={{
                            width: `${rowVirtualizer.getTotalSize()}px`,
                            height: "100%",
                            position: "relative",
                        }}
                    >
                        {rowVirtualizer.getVirtualItems().map((virtualItem) => {
                            return (
                                <div
                                    key={virtualItem.key}
                                    style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        paddingLeft: 5,
                                        paddingRight: 5,
                                        width: `${virtualItem.size}px`,
                                        height: `100%`,
                                        transform: `translateX(${virtualItem.start}px)`,
                                    }}
                                >
                                    {mobileList[virtualItem.index]}
                                </div>
                            );
                        })}
                    </div>
                </Box>
            </VStack>
        </>
    );
};

export default TestimonialsSection;
