import { Box, Grid, GridItem } from "@chakra-ui/react";
import SwiperClass from "swiper";
import { FreeMode, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import ProjectCard from "./ProjectCard";
import { useSwrAllCollections } from "@hooks/swr";
import { useEffect, useRef, useState } from "react";
import GetListedCard from "./GetListedCard";
import { PaddedOnMobile } from "@components/site/layout";
import { useWindowSiteStore } from "@store/windowSiteStore";
import { parseFeaturedCollections } from "@utils/helpers";
import { useVirtualizer } from "@tanstack/react-virtual";
import { isIOS, isAndroid } from "react-device-detect";
import "swiper/css";
import "swiper/css/pagination";

const MIN_SLIDES = 2;
const SLIDE_COLUMNS = 5;
const SLIDE_ROWS = 2;
const SLIDE_ITEMS = SLIDE_COLUMNS * SLIDE_ROWS;
const GETLISTED_CARD_INDEX = 8;
const GETLISTED_CARD_SIZE = 2;

export const FeaturedProjectsSlider = ({ fallbackCollections = null }) => {
    const isMounted = useWindowSiteStore((state) => state.isDesktop);
    const isDesktop = useWindowSiteStore((state) => state.isDesktop);
    const windowWidth = useWindowSiteStore((state) => state.windowWidth);

    const { data: projectsData, error: projectsError } = useSwrAllCollections(fallbackCollections);
    const [featuredProjects, setFeaturedProjects] = useState<CollectionBaseData[]>(
        new Array(SLIDE_ITEMS).fill(null),
    );
    const [initialized, setInitialized] = useState(false);
    const [desktopSwiper, setDesktopSwiper] = useState<SwiperClass>();
    const [mobileSwiper, setMobileSwiper] = useState<SwiperClass>();

    const isMobile = isIOS || isAndroid;

    const mobileSlideWidth = windowWidth > 600 ? 200 : 188;
    const scrollRef = useRef<HTMLDivElement>();
    const rowVirtualizer = useVirtualizer({
        overscan: 8,
        horizontal: true,
        count: featuredProjects?.length || 0,
        getScrollElement: () => scrollRef.current,
        estimateSize: () => mobileSlideWidth + 20,
        paddingStart: 10,
    });

    useEffect(() => {
        if (projectsData?.collections) {
            const { parsedFeatured } = parseFeaturedCollections(
                projectsData?.collections,
                SLIDE_ITEMS * MIN_SLIDES,
                isDesktop ? GETLISTED_CARD_INDEX : null,
                isDesktop ? GETLISTED_CARD_INDEX + GETLISTED_CARD_SIZE : null,
            );

            setFeaturedProjects(parsedFeatured);
            setInitialized(true);

            rowVirtualizer.scrollToOffset(mobileSlideWidth / 2 + 30, { behavior: "auto" });
        }
    }, [projectsData, projectsData?.collections]);

    useEffect(() => {
        if (desktopSwiper && (initialized || !desktopSwiper.isBeginning)) {
            desktopSwiper.slideTo(0, 0);
        }
    }, [desktopSwiper]);

    useEffect(() => {
        if (mobileSwiper) {
            if (!initialized) {
            } else {
                mobileSwiper.slideTo(featuredCount, 0);
            }
        }
    }, [mobileSwiper, initialized]);

    useEffect(() => {
        rowVirtualizer?.measure();
    }, [windowWidth]);

    useEffect(() => {
        rowVirtualizer.scrollToOffset(mobileSlideWidth / 2 + 30, { behavior: "auto" });
    }, []);

    const desktopSlides = new Array(Math.ceil(featuredProjects.length / SLIDE_ITEMS)).fill(1);

    let featuredCount = 0;
    featuredProjects.forEach((project) => {
        if (project) featuredCount++;
    });

    const DesktopSlide = ({ slideIndex }) => {
        return (
            <>
                <Grid
                    templateColumns={`repeat(${SLIDE_COLUMNS}, 1fr)`}
                    templateRows={`repeat(${SLIDE_ROWS}, auto)`}
                    p="10px"
                    gap="20px"
                >
                    {featuredProjects
                        .slice(slideIndex * SLIDE_ITEMS, slideIndex * SLIDE_ITEMS + SLIDE_ITEMS)
                        .map((collection, index) => {
                            if (slideIndex * SLIDE_ITEMS + index == GETLISTED_CARD_INDEX) {
                                return (
                                    <GridItem key={`gl-${index}`} colSpan={GETLISTED_CARD_SIZE}>
                                        <GetListedCard small={isDesktop && windowWidth < 1150} />
                                    </GridItem>
                                );
                            } else if (
                                slideIndex * SLIDE_ITEMS + index > GETLISTED_CARD_INDEX &&
                                slideIndex * SLIDE_ITEMS + index <
                                    GETLISTED_CARD_INDEX + GETLISTED_CARD_SIZE
                            ) {
                                return null;
                            }

                            return (
                                <GridItem
                                    key={`${
                                        collection?.collectionId
                                            ? collection.collectionId
                                            : "filler"
                                    }-${index}`}
                                >
                                    <ProjectCard
                                        collection={collection}
                                        small={isDesktop && windowWidth < 1150}
                                    />
                                </GridItem>
                            );
                        })}
                </Grid>
            </>
        );
    };

    if (isDesktop) {
        return (
            <>
                <PaddedOnMobile>
                    <Box position="relative" w="100%">
                        <Swiper
                            onSwiper={setDesktopSwiper}
                            grabCursor={true}
                            slidesPerView="auto"
                            initialSlide={0}
                            spaceBetween={0}
                            modules={[Pagination]}
                            pagination={{
                                clickable: true,
                            }}
                            loop={true}
                        >
                            {desktopSlides.map((slide, index) => (
                                <SwiperSlide key={`sl-outer-${index}`}>
                                    <DesktopSlide key={`sl-slide-${index}`} slideIndex={index} />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </Box>
                </PaddedOnMobile>
            </>
        );
    }
    // mobile view (screen width < 960px)
    return isMobile ? (
        <Box
            ref={scrollRef}
            position="relative"
            w="100%"
            height="260px"
            overflowX="auto"
            className="no-scrollbars"
            pt="5px"
        >
            <div
                style={{
                    width: `${rowVirtualizer.getTotalSize()}px`,
                    height: "100%",
                    position: "relative",
                }}
            >
                {rowVirtualizer.getVirtualItems().map((virtualItem) => {
                    const collection = featuredProjects[virtualItem.index];
                    return (
                        <div
                            key={virtualItem.key}
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: `${virtualItem.size}px`,
                                height: `100%`,
                                transform: `translateX(${virtualItem.start}px)`,
                            }}
                        >
                            <ProjectCard collection={collection} w={mobileSlideWidth} />
                        </div>
                    );
                })}
            </div>
        </Box>
    ) : (
        <>
            <Box position="relative" w="100%">
                <Swiper
                    onSwiper={setMobileSwiper}
                    grabCursor={true}
                    centeredSlides={true}
                    slidesPerView="auto"
                    initialSlide={0}
                    loopedSlides={featuredCount}
                    spaceBetween={20}
                    modules={[FreeMode]}
                    loop={true}
                    freeMode={{
                        enabled: true,
                        minimumVelocity: 0,
                        momentumRatio: 0.8,
                        momentumBounceRatio: 0.5,
                        sticky: true,
                    }}
                    style={{ padding: "50px 0px", margin: "-50px 0px" }}
                >
                    {featuredProjects.map((collection, index) => {
                        if (
                            index >= GETLISTED_CARD_INDEX &&
                            index < GETLISTED_CARD_INDEX + GETLISTED_CARD_SIZE
                        ) {
                            return null;
                        }

                        return (
                            <SwiperSlide
                                key={`sl-outer-${index}`}
                                style={{ width: mobileSlideWidth }}
                            >
                                <ProjectCard collection={collection} w={mobileSlideWidth} />
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </Box>
            <PaddedOnMobile>
                <GetListedCard isDesktop={false} />
            </PaddedOnMobile>
        </>
    );
};

export default FeaturedProjectsSlider;
