import { Box, Button, Flex, Grid, GridItem, Spacer, VStack } from "@chakra-ui/react";
import GradientButton from "@components/ui/GradientButton";
import { useWindowSiteStore } from "@store/windowSiteStore";
import { scale } from "@utils/helpers";
import { useEffect, useState } from "react";
import { PaddedOnMobile } from "../layout";
import NextLink from "next/link";
import { ExternalIcon } from "@components/icons";
import { motion } from "framer-motion";

const MotionVStack = motion(VStack);
const MotionBox = motion(Box);

type ListCollectionSectionProps = {};

export const ListCollectionSection = ({}: ListCollectionSectionProps) => {
    const isDesktop = useWindowSiteStore((state) => state.isDesktop);
    const windowWidth = useWindowSiteStore((state) => state.windowWidth);

    const minSize = isDesktop ? 960 : 375;
    const maxSize = isDesktop ? 1200 : 960;

    const scaleAnimation = (delay = 0, once = true) => ({
        initial: {
            scale: .8,
            opacity: 0,
        },
        whileInView: {
            scale: 1,
            opacity: 1,
        },
        transition: {
            duration: 0.5,
            ease: "easeOut",
            delay: delay,
        },
        viewport: {
            once: once,
        },
    });

    const slideRightAnimation = (delay = 0, once = true) => ({
        initial: {
            translateX: "-200px",
            opacity: 0,
        },
        whileInView: {
            translateX: "0px",
            opacity: 1,
        },
        transition: {
            duration: 0.8,
            ease: "easeOut",
            delay: delay,
        },
        viewport: {
            once: once,
        },
    });

    const slideLeftAnimation = (delay = 0, once = true) => ({
        initial: {
            translateX: "200px",
            opacity: 0,
        },
        whileInView: {
            translateX: "0px",
            opacity: 1,
        },
        transition: {
            duration: 0.8,
            ease: "easeOut",
            delay: delay,
        },
        viewport: {
            once: once,
        },
    });

    return (
        <>
            <Flex
                pos="relative"
                w="100%"
                minH={scale(windowWidth, minSize, maxSize, 600, 1120) + "px"}
                justify="center"
                align="center"
            >
                <Box
                    pos="absolute"
                    minW="100vw"
                    w="100vw"
                    h="100%"
                    minH="100%"
                    backgroundImage={`url('/images/home/paintsplatter.png')`}
                    backgroundRepeat="no-repeat"
                    backgroundPosition="center"
                    bgSize="auto 100%"
                    zIndex={0}
                />
                <PaddedOnMobile
                    my={isDesktop ? 0 : 150}
                    mt={
                        isDesktop
                            ? "-" + scale(windowWidth, minSize, maxSize, 90, 120) + "px"
                            : undefined
                    }
                >
                    <Grid
                        maxW={isDesktop ? undefined : "450px"}
                        templateColumns={isDesktop ? "1fr 1fr" : "auto"}
                        templateRows="auto"
                    >
                        <GridItem
                            pr={isDesktop ? 14 : 0}
                            pb={isDesktop ? 0 : 14}
                            borderRight={isDesktop ? "1px solid" : "none"}
                            borderBottom={isDesktop ? "none" : "1px solid"}
                            borderColor="lavenderGray"
                        >
                            <MotionVStack w="100%" h="100%" {...slideRightAnimation()}>
                                <Flex w="100%" direction="column" gap={8}>
                                    <Box
                                        fontFamily="regular"
                                        fontSize={scale(windowWidth, minSize, maxSize, 32, 48)}
                                        lineHeight="110%"
                                        textAlign="center"
                                    >
                                        Are you a<br />
                                        Project Creator?
                                    </Box>
                                    <Box
                                        pt={2}
                                        fontSize={scale(windowWidth, minSize, maxSize, 16, 24)}
                                        fontWeight="light"
                                        textAlign="justify"
                                    >
                                        Want to see your project on Beyond Rarity, and empower your
                                        community to visualize and trade your collection in
                                        completely new ways?
                                        <br />
                                        <br />
                                        Fight the bear market and get listed for free for a limited
                                        time during the Beta.
                                    </Box>
                                </Flex>
                                <Spacer />
                                <MotionBox w="100%" pt={16} {...scaleAnimation()}>
                                    <GradientButton
                                        link="/getlisted"
                                        label="Get your collection listed"
                                        buttonStyle={{ w: "100%" }}
                                    />
                                </MotionBox>
                            </MotionVStack>
                        </GridItem>

                        <GridItem
                            pl={isDesktop ? 14 : 0}
                            pt={isDesktop ? 0 : 14}
                            borderLeft={isDesktop ? "1px solid" : "none"}
                            borderTop={isDesktop ? "none" : "1px solid"}
                            borderColor="lavenderGray"
                        >
                            <MotionVStack w="100%" h="100%" {...slideLeftAnimation()}>
                                <Flex w="100%" direction="column" gap={8}>
                                    <Box
                                        fontFamily="regular"
                                        fontSize={scale(windowWidth, minSize, maxSize, 32, 48)}
                                        lineHeight="110%"
                                        textAlign="center"
                                    >
                                        Are you a<br />
                                        Project Fan?
                                    </Box>
                                    <Box
                                        pt={2}
                                        fontSize={scale(windowWidth, minSize, maxSize, 16, 24)}
                                        fontWeight="light"
                                        textAlign="justify"
                                    >
                                        Don’t see your favorite NFT collection on Beyond Rarity?
                                        <br />
                                        <br />
                                        We’re adding new ones every day, but if we haven’t gotten to
                                        it, let us know and we’ll add it ASAP.
                                    </Box>
                                </Flex>
                                <Spacer />
                                <MotionBox w="100%" pt={16} {...scaleAnimation()}>
                                    {/* <GradientButton
                                        link="https://form.jotform.com/BeyondRarity/request-project"
                                        label="Get your collection listed"
                                        buttonStyle={{ w: "100%" }}
                                        isExternal
                                        invertButtonColor
                                    /> */}
                                    <NextLink
                                        href="https://form.jotform.com/BeyondRarity/request-project"
                                        passHref
                                    >
                                        <a target="_blank">
                                            <Flex
                                                w="100%"
                                                _hover={{ textDecor: "none" }}
                                                // onClick={handleOnMenuClick}
                                            >
                                                <Button
                                                    py={5}
                                                    w="100%"
                                                    variant="white"
                                                    fontFamily="regular"
                                                    fontSize={{ base: "18px", md: "22px" }}
                                                    // fontWeight="300"
                                                >
                                                    <Flex justify="center" align="center" gap="4px">
                                                        Request a collection
                                                    </Flex>
                                                    <ExternalIcon ml="4px" fontSize="60%" />
                                                </Button>
                                            </Flex>
                                        </a>
                                    </NextLink>
                                </MotionBox>
                            </MotionVStack>
                        </GridItem>
                    </Grid>
                </PaddedOnMobile>
            </Flex>
        </>
    );
};

export default ListCollectionSection;
