import { Box, Flex, Grid, GridItem, Text } from "@chakra-ui/react";
import { useWindowSiteStore } from "@store/windowSiteStore";
import { useEffect, useState } from "react";
import { PaddedOnMobile } from "../layout";
import { scale } from "@utils/helpers";
import { motion } from "framer-motion";

const MotionBox = motion(Box);

type VisionSectionProps = {};

export const VisionSection = ({}: VisionSectionProps) => {
    const isDesktop = useWindowSiteStore((state) => state.isDesktop);
    const windowWidth = useWindowSiteStore((state) => state.windowWidth);

    const minSize = isDesktop ? 960 : 375;
    const maxSize = isDesktop ? 1200 : 960;

    const dividerHeight = isDesktop ? 844 : scale(windowWidth, minSize, maxSize, 252, 844);
    const dividerImageUrl = "/images/home/divider.png";

    const goodDealHeight = isDesktop ? 887 : scale(windowWidth, minSize, maxSize, 330, 887);
    const goodDealImageUrl = isDesktop
        ? "/images/home/good-deal-desktop.png"
        : "/images/home/good-deal-mobile.png";

    const scaleAnimation = (delay = 0, once = true) => ({
        initial: {
            scale: .8,
            opacity: 0,
        },
        whileInView: {
            scale: 1,
            opacity: 1,
        },
        transition: {
            duration: 0.5,
            ease: "easeOut",
            delay: delay,
        },
        viewport: {
            once: once,
        },
    });

    if (isDesktop) {
        return (
            <>
                <Box
                    pos="relative"
                    minW="100%"
                    h={Math.max(dividerHeight, goodDealHeight)}
                    my="50px"
                >
                    <Box
                        pos="absolute"
                        top={goodDealHeight - dividerHeight}
                        minW="100vw"
                        w="100vw"
                        h={dividerHeight}
                        minH={dividerHeight}
                        backgroundImage={`url('${dividerImageUrl}')`}
                        backgroundRepeat="no-repeat"
                        backgroundPosition="top center"
                        bgSize="auto 100%"
                        zIndex={0}
                    />
                    <PaddedOnMobile pos="relative">
                        <MotionBox
                            pos="absolute"
                            minW="100%"
                            w="100%"
                            h={goodDealHeight}
                            minH={goodDealHeight}
                            backgroundImage={`url('${goodDealImageUrl}')`}
                            backgroundRepeat="no-repeat"
                            backgroundPosition="30% 0"
                            zIndex={0}
                            bgSize="auto 100%"
                            {...scaleAnimation()}
                        />
                        <Grid
                            h={goodDealHeight}
                            w="100%"
                            templateColumns="55% 45%"
                            templateRows="1fr 1fr"
                            zIndex={1}
                        >
                            <GridItem pl={10} pb={100}>
                                <Flex
                                    fontFamily="regular"
                                    fontSize={scale(windowWidth, minSize, maxSize, 55, 72)}
                                    direction="column"
                                    h="100%"
                                    justify="end"
                                    align="start"
                                >
                                    <Box>
                                        <Box color="hotOrange" fontSize="24px" lineHeight="100%">
                                            We answer the Question:
                                        </Box>
                                        <Box lineHeight="100%">
                                            Is this token
                                            <br />a good deal?
                                        </Box>
                                    </Box>
                                </Flex>
                            </GridItem>
                            <GridItem />
                            <GridItem />
                            <GridItem pb={100}>
                                <Flex
                                    fontFamily="regular"
                                    fontSize={scale(windowWidth, minSize, maxSize, 32, 48)}
                                    direction="column"
                                    h="100%"
                                    justify="end"
                                    align="end"
                                >
                                    <Box>
                                        <Box color="hotOrange" fontSize="24px" lineHeight="100%">
                                            Our Answer:
                                        </Box>
                                        <Box lineHeight="100%">
                                            Yes, if it’s below our
                                            <br />
                                            price projection line!
                                        </Box>
                                    </Box>
                                </Flex>
                            </GridItem>
                        </Grid>
                    </PaddedOnMobile>
                </Box>
            </>
        );
    }

    return (
        <>
            <Flex direction="column" gap={8}>
                <PaddedOnMobile pos="relative">
                    <Flex
                        fontFamily="regular"
                        fontSize={scale(windowWidth, minSize, maxSize, 36, 72)}
                        direction="column"
                        h="100%"
                        justify="end"
                        align="start"
                    >
                        <Box>
                            <Box
                                color="hotOrange"
                                fontSize={scale(windowWidth, minSize, maxSize, 12, 24)}
                                lineHeight="100%"
                            >
                                We answer the Question:
                            </Box>
                            <Box lineHeight="100%">
                                Is this token
                                <br />a good deal?
                            </Box>
                        </Box>
                    </Flex>
                </PaddedOnMobile>
                <Box pos="relative" minW="100vw" h={Math.max(dividerHeight, goodDealHeight)}>
                    <Box
                        pos="absolute"
                        top={(goodDealHeight - dividerHeight) / 2}
                        minW="100vw"
                        w="100vw"
                        h={dividerHeight}
                        minH={dividerHeight}
                        backgroundImage={`url('${dividerImageUrl}')`}
                        backgroundRepeat="no-repeat"
                        backgroundPosition="top center"
                        bgSize="auto 100%"
                        zIndex={0}
                    />
                    <MotionBox
                        pos="absolute"
                        minW="100vw"
                        w="100vw"
                        h={goodDealHeight}
                        minH={goodDealHeight}
                        backgroundImage={`url('${goodDealImageUrl}')`}
                        backgroundRepeat="no-repeat"
                        backgroundPosition="top center"
                        bgSize="auto 100%"
                        zIndex={0}
                        {...scaleAnimation()}
                    />
                </Box>
                <PaddedOnMobile pos="relative">
                    <Flex
                        fontFamily="regular"
                        fontSize={scale(windowWidth, minSize, maxSize, 24, 48)}
                        direction="column"
                        h="100%"
                        justify="end"
                        align="start"
                    >
                        <Box>
                            <Box
                                color="hotOrange"
                                fontSize={scale(windowWidth, minSize, maxSize, 12, 24)}
                                lineHeight="100%"
                            >
                                Our Answer:
                            </Box>
                            <Box lineHeight="100%">
                                Yes, if it’s below our
                                <br />
                                price projection line!
                            </Box>
                        </Box>
                    </Flex>
                </PaddedOnMobile>
            </Flex>
        </>
    );
};

export default VisionSection;
