import { Box, Text, VStack } from "@chakra-ui/react";
import { useWindowSiteStore } from "@store/windowSiteStore";
import { scale } from "@utils/helpers";
import { PaddedOnMobile } from "../layout";

type ValuePropSectionProps = {};

export const ValuePropSection = ({}: ValuePropSectionProps) => {
    const isDesktop = useWindowSiteStore((state) => state.isDesktop);
    const windowWidth = useWindowSiteStore((state) => state.windowWidth);

    const minSize = isDesktop ? 960 : 375;
    const maxSize = isDesktop ? 1200 : 960;

    return (
        <>
            <PaddedOnMobile>
                <VStack
                    fontFamily="regular"
                    fontSize={scale(
                        windowWidth,
                        minSize,
                        maxSize,
                        isDesktop ? 36 : 24,
                        isDesktop ? 48 : 36,
                    )}
                    align="start"
                >
                    <Box color="gold.500" fontSize="50%" lineHeight="110%">
                        Pro level power without the complexity
                    </Box>
                    <Box lineHeight="130%">
                        Quick & easy price estimates for NFTs.
                        {windowWidth > 600 ? <br /> : " "}
                        <Text as="span" fontWeight="semibold">
                            Instantly
                        </Text>
                        , with{" "}
                        <Text as="span" fontWeight="semibold">
                            confidence
                        </Text>
                        , in{" "}
                        <Text as="span" fontWeight="semibold">
                            real time
                        </Text>
                    </Box>
                </VStack>
            </PaddedOnMobile>
        </>
    );
};

export default ValuePropSection;
