import useSWR from "swr";
import { API } from "@config/app";
import { getPathInfo } from "@utils";

export const defaultFetcher = (input: RequestInfo, init?: RequestInit) => {
    // console.debug("defaultFetcher input: ", input);

    const result = fetch(input, init).then((res) => res.json());

    // console.debug("defaultFetcher result: ", result);

    return result;
};

export const useSwrAllCollections = (fallbackData = null) => {
    const isValidRequest = true;
    const { isStage } = getPathInfo();
    const apiPath = `${API.DOMAIN}${isStage ? "/stage" : ""}${API.PATH}`;

    const apiUrl = isValidRequest ? `${apiPath}/collections/all` : null;
    // const apiUrl = isValidRequest ? `${API.PASS_THROUGH}/collections/all` : null;
    // console.debug("apiUrl: ", apiUrl);

    const { data, error } = useSWR(apiUrl, defaultFetcher, {
        refreshInterval: 1000 * 60 * 5, // 5 min
        revalidateOnFocus: false,
        revalidateOnReconnect: true,
        fallbackData: fallbackData,
    });

    if (data) {
        data.collections.forEach((collection) => {
            // TODO: Do on server side.
            collection.featuredStartDate =
                collection.featuredStartDate != null
                    ? new Date(Date.parse(collection.featuredStartDate))
                    : new Date(0);
            collection.featuredEndDate =
                collection.featuredEndDate != null
                    ? new Date(Date.parse(collection.featuredEndDate))
                    : new Date(8640000000000000);
            collection.firstPublishedToProduction =
                collection.firstPublishedToProduction != null
                    ? new Date(Date.parse(collection.firstPublishedToProduction))
                    : new Date(0);

            // TODO: Do on server side.
            collection.imageUrl = collection.imageUrl?.replace(/(\?.*)(w=.+)(&.+)/gm, "$1w=1$3");
        });
    }

    return { data, error };
};

export const useSwrCollectionStats = (collectionId, refreshInterval = 0) => {
    const { isStage } = getPathInfo();
    const apiPath = `${API.DOMAIN}${isStage ? "/stage" : ""}${API.PATH}`;
    const isValidRequest = collectionId != null;

    const apiUrl = isValidRequest ? `${apiPath}/collections/${collectionId}/stats` : null;

    const { data, error, isValidating } = useSWR<CollectionStats, any>(apiUrl, defaultFetcher, {
        refreshInterval: refreshInterval,
        revalidateOnReconnect: false,
        revalidateOnFocus: false,
    });

    return { data, error, isValidating };
};

export const useSwrMarketInfo = () => {
    const { isStage } = getPathInfo();
    const apiPath = `${API.DOMAIN}${isStage ? "/stage" : ""}${API.PATH}`;
    const isValidRequest = true;

    const apiUrl = isValidRequest ? `${apiPath}/market-info` : null;

    const { data, error } = useSWR<MarketInfo, any>(apiUrl, defaultFetcher, {
        refreshInterval: 15000,
        revalidateOnReconnect: true,
    });

    return { data, error };
};

export const useSwrSubscriberStatus = ({ list = "beta", email }) => {
    const isValidRequest = email && email !== "";

    const apiUrl = isValidRequest ? `/api/v1/subscribers/${list}/${email}/status` : null;

    const { data, error } = useSWR<any, any>(apiUrl, defaultFetcher, {
        refreshInterval: 0,
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
    });

    return { data, error };
};
