import { Box, Flex, Grid, GridItem, Icon, Image, VStack } from "@chakra-ui/react";
import { useWindowSiteStore } from "@store/windowSiteStore";
import { scale } from "@utils/helpers";
import { PaddedOnMobile } from "../layout";
import { motion } from "framer-motion";
import { Fragment } from "react";

const MotionGridItem = motion(GridItem);
const MotionImage = motion(Image);

const StrongerSectionData = {
    label: "Stronger",
    labelColor: "sorbet",
    title: {
        desktop: (
            <>
                Rarity...
                <br />
                and beyond
            </>
        ),
        mobile: " Rarity... and beyond",
    },
    subtitle:
        "Rarity's great, but if you're using it to estimate pricing, there's no need. We've done it for you.",
    bullets: [
        {
            icon: "/icons/instant-rarity.svg",
            title: "Instant Rarity & Rankings",
            description: "The best way to visualize rarity. Period.",
            comingSoon: false,
        },
        {
            icon: "/icons/instant-price.svg",
            title: "Instant Price Estimates",
            description: "Answering your most important question",
            comingSoon: false,
        },
        {
            icon: "/icons/real-time-data.svg",
            title: "Real-time data",
            description: "Constantly refreshed for the latest",
            comingSoon: false,
        },
    ],
};

type StrongerSectionProps = {};

export const StrongerSection = ({}: StrongerSectionProps) => {
    const isDesktop = useWindowSiteStore((state) => state.isDesktop);
    const windowWidth = useWindowSiteStore((state) => state.windowWidth);

    const minSize = isDesktop ? 960 : 375;
    const maxSize = isDesktop ? 1200 : 960;

    const strongerImage = "/images/home/stronger.png";

    const scaleAnimation = (delay = 0, once = true) => ({
        initial: {
            scale: 0.8,
            opacity: 0,
        },
        whileInView: {
            scale: 1,
            opacity: 1,
        },
        transition: {
            duration: 0.5,
            ease: "easeOut",
            delay: delay,
        },
        viewport: {
            once: once,
        },
    });

    const slideUpAnimation = (delay = 0, once = true) => ({
        initial: {
            translateY: "50px",
            opacity: 0,
        },
        whileInView: {
            translateY: "0px",
            opacity: 1,
        },
        transition: {
            duration: 0.8,
            ease: "easeOut",
            delay: delay,
        },
        viewport: {
            once: once,
        },
    });

    const slideRightAnimation = (delay = 0, once = true) => ({
        initial: {
            translateX: "-200px",
            opacity: 0,
        },
        whileInView: {
            translateX: "0px",
            opacity: 1,
        },
        transition: {
            duration: 0.8,
            ease: "easeOut",
            delay: delay,
        },
        viewport: {
            once: once,
        },
    });

    if (isDesktop) {
        return (
            <>
                <PaddedOnMobile>
                    <Grid w="100%" templateColumns="1fr 50%">
                        <GridItem>
                            <Flex direction="column" w="100%">
                                <Box pr={8}>
                                    <Box
                                        fontFamily="regular"
                                        fontSize={scale(windowWidth, minSize, maxSize, 18, 24)}
                                        color={StrongerSectionData.labelColor}
                                    >
                                        {StrongerSectionData.label}
                                    </Box>
                                    <Box
                                        fontFamily="regular"
                                        fontSize={scale(windowWidth, minSize, maxSize, 55, 72)}
                                        lineHeight="110%"
                                    >
                                        {StrongerSectionData.title.desktop}
                                    </Box>
                                    <Box
                                        pt={2}
                                        pb={6}
                                        fontSize={scale(windowWidth, minSize, maxSize, 20, 24)}
                                        fontWeight="light"
                                    >
                                        {StrongerSectionData.subtitle}
                                    </Box>
                                </Box>
                                <Grid
                                    w="100%"
                                    templateColumns="auto"
                                    templateRows="auto auto auto"
                                    rowGap={4}
                                    pb="5%"
                                >
                                    {StrongerSectionData.bullets.map((bullet, index) => {
                                        return (
                                            <Fragment key={index}>
                                                <MotionGridItem {...slideRightAnimation(0.2)}>
                                                    <Grid templateColumns="auto 1fr" columnGap={3}>
                                                        <GridItem>
                                                            <Icon
                                                                as={Image}
                                                                src={bullet.icon}
                                                                w={
                                                                    scale(
                                                                        windowWidth,
                                                                        minSize,
                                                                        maxSize,
                                                                        70,
                                                                        85,
                                                                    ) + "px"
                                                                }
                                                                h={
                                                                    scale(
                                                                        windowWidth,
                                                                        minSize,
                                                                        maxSize,
                                                                        70,
                                                                        85,
                                                                    ) + "px"
                                                                }
                                                            />
                                                        </GridItem>
                                                        <GridItem>
                                                            <Flex
                                                                direction="column"
                                                                justify="center"
                                                                h="100%"
                                                                gap={1}
                                                            >
                                                                <Box
                                                                    fontFamily="regular"
                                                                    fontSize={scale(
                                                                        windowWidth,
                                                                        minSize,
                                                                        maxSize,
                                                                        24,
                                                                        30,
                                                                    )}
                                                                    lineHeight="110%"
                                                                >
                                                                    {bullet.title}
                                                                </Box>
                                                                <Box
                                                                    fontSize={scale(
                                                                        windowWidth,
                                                                        minSize,
                                                                        maxSize,
                                                                        18,
                                                                        24,
                                                                    )}
                                                                    fontWeight="light"
                                                                    color="lavenderGray"
                                                                    lineHeight="110%"
                                                                >
                                                                    {bullet.description}
                                                                </Box>
                                                                {bullet.comingSoon && (
                                                                    <Box
                                                                        fontFamily="regular"
                                                                        fontSize={scale(
                                                                            windowWidth,
                                                                            minSize,
                                                                            maxSize,
                                                                            10,
                                                                            15,
                                                                        )}
                                                                        fontWeight="medium"
                                                                        color="lavenderGray"
                                                                        lineHeight="110%"
                                                                    >
                                                                        Coming Soon
                                                                    </Box>
                                                                )}
                                                            </Flex>
                                                        </GridItem>
                                                    </Grid>
                                                </MotionGridItem>
                                            </Fragment>
                                        );
                                    })}
                                </Grid>
                            </Flex>
                        </GridItem>
                        <GridItem>
                            <Flex h="100%" align="end">
                                <MotionImage src={strongerImage} {...scaleAnimation()} />
                            </Flex>
                        </GridItem>
                    </Grid>
                </PaddedOnMobile>
            </>
        );
    }

    return (
        <>
            <PaddedOnMobile>
                <VStack gap={4} maxW="600px">
                    <Flex direction="column" maxW="95%">
                        <Box
                            fontFamily="regular"
                            fontSize={scale(windowWidth, minSize, maxSize, 12, 24)}
                            color={StrongerSectionData.labelColor}
                        >
                            {StrongerSectionData.label}
                        </Box>
                        <Box
                            fontFamily="regular"
                            fontSize={scale(windowWidth, minSize, maxSize, 32, 55)}
                            lineHeight="110%"
                        >
                            {StrongerSectionData.title.mobile}
                        </Box>
                        <Box
                            pt={2}
                            pb={4}
                            fontSize={scale(windowWidth, minSize, maxSize, 16, 24)}
                            fontWeight="light"
                        >
                            {StrongerSectionData.subtitle}
                        </Box>
                    </Flex>

                    <Flex w="100%" align="end">
                        <MotionImage src={strongerImage} {...scaleAnimation()} />
                    </Flex>

                    <Grid
                        templateColumns="auto 1fr"
                        templateRows="auto auto auto"
                        columnGap={2}
                        rowGap={8}
                    >
                        {StrongerSectionData.bullets.map((bullet, index) => {
                            return (
                                <Fragment key={index}>
                                    <MotionGridItem {...slideUpAnimation(0.2)}>
                                        <Icon
                                            as={Image}
                                            src={bullet.icon}
                                            w={scale(windowWidth, minSize, maxSize, 60, 85) + "px"}
                                            h={scale(windowWidth, minSize, maxSize, 60, 85) + "px"}
                                        />
                                    </MotionGridItem>
                                    <MotionGridItem {...slideUpAnimation(0.2)}>
                                        <Flex direction="column" justify="center" h="100%" gap={1}>
                                            <Box
                                                fontFamily="regular"
                                                fontSize={scale(
                                                    windowWidth,
                                                    minSize,
                                                    maxSize,
                                                    18,
                                                    30,
                                                )}
                                                lineHeight="110%"
                                            >
                                                {bullet.title}
                                            </Box>
                                            <Box
                                                fontSize={scale(
                                                    windowWidth,
                                                    minSize,
                                                    maxSize,
                                                    16,
                                                    24,
                                                )}
                                                fontWeight="light"
                                                color="lavenderGray"
                                                lineHeight="110%"
                                            >
                                                {bullet.description}
                                            </Box>
                                            {bullet.comingSoon && (
                                                <Box
                                                    fontFamily="regular"
                                                    fontSize={scale(
                                                        windowWidth,
                                                        minSize,
                                                        maxSize,
                                                        10,
                                                        15,
                                                    )}
                                                    fontWeight="medium"
                                                    color="lavenderGray"
                                                    lineHeight="110%"
                                                >
                                                    Coming Soon
                                                </Box>
                                            )}
                                        </Flex>
                                    </MotionGridItem>
                                </Fragment>
                            );
                        })}
                    </Grid>
                </VStack>
            </PaddedOnMobile>
        </>
    );
};

export default StrongerSection;
