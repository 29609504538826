import {
    AspectRatio,
    Box,
    Flex,
    Grid,
    GridItem,
    Modal,
    ModalContent,
    ModalOverlay,
    VStack,
} from "@chakra-ui/react";
import { useWindowSiteStore } from "@store/windowSiteStore";
import { AnimatePresence } from "framer-motion";
import TutorialListItem from "@components/tutorials/TutorialListItem";
import TutorialVideoPlayer from "@components/tutorials/TutorialVideoPlayer";
import { useEffect, useState } from "react";
import { PaddedOnMobile } from "../layout";
import MobileCloseButton from "@components/tutorials/MobileCloseButton";
import { scale } from "@utils/helpers";
import TutorialsGradientButton from "@components/tutorials/TutorialsGradientButton";
import { motion } from "framer-motion";

const MotionGridItem = motion(GridItem);
const MotionBox = motion(Box);

type TutorialsSectionProps = {
    onClose?: Function;
};

export const TutorialsSection = ({ onClose }: TutorialsSectionProps) => {
    const isDesktop = useWindowSiteStore((state) => state.isDesktop);
    const windowWidth = useWindowSiteStore((state) => state.windowWidth);
    const [playingVideoId, setPlayingVideoId] = useState<string>();

    const minSize = isDesktop ? 960 : 375;
    const maxSize = isDesktop ? 1200 : 960;

    const onVideoComplete = () => {
        setPlayingVideoId("");
    };

    const onMobileCloseClick = () => {
        if (playingVideoId) {
            setPlayingVideoId("");
        } else {
            onClose?.();
        }
    };

    const scaleAnimation = (delay = 0, once = true) => ({
        initial: {
            scale: .8,
            opacity: 0,
        },
        whileInView: {
            scale: 1,
            opacity: 1,
        },
        transition: {
            duration: 0.5,
            ease: "easeOut",
            delay: delay,
        },
        viewport: {
            once: once,
        },
    });

    return (
        <>
            {isDesktop ? (
                <>
                    <Box pos="relative" minW="100vw" bgColor="spaceCadetCardAlpha.600" py="100px">
                        <PaddedOnMobile>
                            <Grid w="100%" templateColumns="1fr 1fr" columnGap={14} rowGap={20}>
                                <MotionGridItem {...scaleAnimation()}>
                                    <TutorialListItem
                                        // VIDEO = INSIDE OUR TECH
                                        videoId="8IBIfjyOaq0" // youtube
                                        onPlayVideo={setPlayingVideoId}
                                    />
                                </MotionGridItem>
                                <GridItem>
                                    <Flex direction="column" pr={20}>
                                        <Box
                                            fontFamily="regular"
                                            fontSize={scale(windowWidth, minSize, maxSize, 12, 24)}
                                            color="sorbet"
                                        >
                                            Tech
                                        </Box>
                                        <Box
                                            fontFamily="regular"
                                            fontSize={scale(windowWidth, minSize, maxSize, 32, 48)}
                                            lineHeight="110%"
                                        >
                                            Frontend Beauty.
                                            <br />
                                            Backend Beast.
                                        </Box>
                                        <Box
                                            pt={2}
                                            fontSize={scale(windowWidth, minSize, maxSize, 18, 24)}
                                            fontWeight="light"
                                        >
                                            Peek under the hood at our cutting-edge data science
                                            tech
                                        </Box>
                                    </Flex>
                                </GridItem>

                                <MotionGridItem {...scaleAnimation()}>
                                    <TutorialListItem
                                        // VIDEO = FIND DEALS FAST
                                        videoId="yKZaVG3AAIg" // youtube
                                        onPlayVideo={setPlayingVideoId}
                                    />
                                </MotionGridItem>
                                <GridItem>
                                    <Flex direction="column">
                                        <Box
                                            fontFamily="regular"
                                            fontSize={scale(windowWidth, minSize, maxSize, 12, 24)}
                                            color="sorbet"
                                        >
                                            How-to
                                        </Box>
                                        <Box
                                            fontFamily="regular"
                                            fontSize={scale(windowWidth, minSize, maxSize, 32, 48)}
                                            lineHeight="110%"
                                        >
                                            Get Instant Deals
                                        </Box>
                                        <Box
                                            pt={2}
                                            fontSize={scale(windowWidth, minSize, maxSize, 18, 24)}
                                            fontWeight="light"
                                        >
                                            Find deals in seconds after this 00:40 second tutorial
                                        </Box>
                                    </Flex>
                                </GridItem>

                                <MotionGridItem mt={-6} {...scaleAnimation()}>
                                    <TutorialsGradientButton buttonStyle={{ w: "100%" }} />
                                </MotionGridItem>
                                <GridItem />
                            </Grid>
                        </PaddedOnMobile>
                    </Box>{" "}
                </>
            ) : (
                <>
                    <Box pos="relative" minW="100vw" bgColor="spaceCadetCardAlpha.600" py="100px">
                        <PaddedOnMobile>
                            <VStack gap={10} maxW="600px">
                                <VStack w="100%" gap={6}>
                                    <Flex w="100%" direction="column">
                                        <Box
                                            fontFamily="regular"
                                            fontSize={scale(windowWidth, minSize, maxSize, 12, 24)}
                                            color="sorbet"
                                        >
                                            Tech
                                        </Box>
                                        <Box
                                            fontFamily="regular"
                                            fontSize={scale(windowWidth, minSize, maxSize, 32, 48)}
                                            lineHeight="110%"
                                        >
                                            Frontend Beauty.
                                            <br />
                                            Backend Beast.
                                        </Box>
                                        <Box
                                            pt={2}
                                            fontSize={scale(windowWidth, minSize, maxSize, 16, 24)}
                                            fontWeight="light"
                                        >
                                            Peek under the hood at our cutting-edge data science
                                            tech
                                        </Box>
                                    </Flex>
                                    <MotionBox w="100%" {...scaleAnimation()}>
                                        <TutorialListItem
                                            // VIDEO = INSIDE OUR TECH
                                            videoId="8IBIfjyOaq0" // youtube
                                            onPlayVideo={setPlayingVideoId}
                                        />
                                    </MotionBox>
                                </VStack>

                                <VStack pt={10} w="100%" gap={6}>
                                    <Flex w="100%" direction="column">
                                        <Box
                                            fontFamily="regular"
                                            fontSize={scale(windowWidth, minSize, maxSize, 12, 24)}
                                            color="sorbet"
                                        >
                                            How-to
                                        </Box>
                                        <Box
                                            fontFamily="regular"
                                            fontSize={scale(windowWidth, minSize, maxSize, 32, 48)}
                                            lineHeight="110%"
                                        >
                                            Get Instant Deals
                                        </Box>
                                        <Box
                                            pt={2}
                                            fontSize={scale(windowWidth, minSize, maxSize, 16, 24)}
                                            fontWeight="light"
                                        >
                                            Find deals in seconds after this 00:40 second tutorial
                                        </Box>
                                    </Flex>
                                    <MotionBox w="100%" {...scaleAnimation()} >
                                        <TutorialListItem
                                            // VIDEO = FIND DEALS FAST
                                            videoId="yKZaVG3AAIg" // youtube
                                            onPlayVideo={setPlayingVideoId}
                                        />
                                    </MotionBox>
                                </VStack>

                                <MotionBox w="60%" {...scaleAnimation()} >
                                    <TutorialsGradientButton buttonStyle={{ w: "100%" }} />
                                </MotionBox>
                            </VStack>
                        </PaddedOnMobile>
                    </Box>
                </>
            )}
            {playingVideoId && (
                <>
                    <AnimatePresence>
                        <Modal
                            isOpen
                            onClose={onVideoComplete}
                            blockScrollOnMount={true}
                            isCentered
                        >
                            <ModalOverlay
                                bgColor="#000e"
                                backdropFilter="auto"
                                backdropBlur="2px"
                            />
                            <ModalContent
                                position="relative"
                                w={isDesktop ? "95vw" : "100vw"}
                                maxW={isDesktop ? { base: "420px", sm2: "960px" } : "100vw"}
                                maxH={isDesktop ? "90vh" : "100vh"}
                                bgColor="transparent"
                            >
                                {/* {isDesktop && (
                                <Flex
                                    layerStyle="tutorials.modal.closeButton"
                                    display={{ base: "none", sm2: "flex" }}
                                    _hover={{ bgColor: "gold.500", cursor: "pointer" }}
                                    onClick={onVideoComplete}
                                >
                                    <CloseIcon w="12px" h="12px" />
                                </Flex>
                            )} */}
                                <Flex w="100%" h="100%" justify="center" align="center">
                                    <Box
                                        position="relative"
                                        w="100%"
                                        h="100%"
                                        borderRadius={isDesktop ? 10 : 0}
                                        overflow="hidden"
                                    >
                                        <AspectRatio ratio={16 / 9}>
                                            <Box>
                                                <TutorialVideoPlayer
                                                    videoId={playingVideoId}
                                                    onVideoComplete={onVideoComplete}
                                                    isDesktop={isDesktop}
                                                    useSetMotionBoxHeight={false}
                                                    motionBoxProps={{ top: 0, h: "100%" }}
                                                    containerProps={{
                                                        mt: "auto",
                                                        transform: "none",
                                                        h: "100%",
                                                    }}
                                                />
                                            </Box>
                                        </AspectRatio>
                                    </Box>
                                </Flex>
                            </ModalContent>
                        </Modal>
                    </AnimatePresence>
                    {!isDesktop && <MobileCloseButton onClick={onMobileCloseClick} />}
                </>
            )}
        </>
    );
};

export default TutorialsSection;
