import {
    AspectRatio,
    Box,
    Flex,
    Input,
    InputGroup,
    InputRightAddon,
    ResponsiveValue,
    Spinner,
} from "@chakra-ui/react";
import chakraTheme from "@theme";
import { CheckmarkTickIcon } from "@components/icons";
import GradientButton from "@components/ui/GradientButton";
import { motion } from "framer-motion";
import { memo, useEffect, useState } from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";

const MotionBox = motion(Box);

type SubscribeFormProps = {
    emailPlaceholderText?: string;
    submitButtonLabel?: string;
    height?: ResponsiveValue<number | string>;
    inputFontSize?: ResponsiveValue<number | string>;
    buttonWidth?: ResponsiveValue<number | string>;
    buttonPadding?: ResponsiveValue<number | string>;
    buttonFontSize?: ResponsiveValue<number | string>;
    statusFontSize?: ResponsiveValue<number | string>;
    useMotion?: boolean;
};

export const SubscribeForm = ({
    emailPlaceholderText = "Enter your email",
    submitButtonLabel = "Subscribe",
    height = { base: "40px", lg: "50px" },
    inputFontSize,
    buttonWidth,
    buttonPadding,
    buttonFontSize,
    statusFontSize,
    useMotion=false,
}: SubscribeFormProps) => {
    const postUrl = `https://wallstfam.us17.list-manage.com/subscribe/post?u=d3dbffdde7ea064448fe70fda&amp;id=d956c7a813&amp;f_id=00d643e0f0`;

    const CustomForm = ({ status, message, onValidated }) => {
        const [email, setEmail] = useState("");
        const [validEmail, setValidEmail] = useState(false);

        // console.debug(status + ": " + message);
        // console.debug("lastMCResult: ", lastMCResult);

        const handleSubmit = (e) => {
            e.preventDefault();

            const formData = {
                EMAIL: email,
                // TWTT: twitterHandle,
            };

            // console.debug("form data: ", formData);

            email && isValidEmail(email) && onValidated(formData);
        };

        const isSent = status === "error" || status === "success";

        useEffect(() => {
            setValidEmail(isValidEmail(email));
        }, [email]);

        // useEffect(() => {
        //     if (status === "success") {
        //         clearFields();
        //         setLastSubscribedToBetaDate(Date.now());
        //     }
        //     if (status != null) {
        //         setLastMCResult({
        //             status: status,
        //             message: message,
        //         });
        //     }
        // }, [status, message]);

        const clearFields = () => {
            setEmail("");
        };

        const isValidEmail = (email) => {
            return /\S+@\S+\.\S+/.test(email);
        };

        const getPlaceholderColor = () => {
            return status == "success" ? "#fff" : "lavenderGray";
        };

        const getPlaceholderText = () => {
            if (status == "success") {
                return "Almost there!";
            }
            return emailPlaceholderText;
        };

        const FormSubmitButton = () => {
            if (status === "sending" || status === "sending") {
                return (
                    <Box>
                        <Spinner
                            color="#fff"
                            mr={{ base: "6px", md: "8px" }}
                            w={{ base: "18px", md: "36px" }}
                            h={{ base: "18px", md: "36px" }}
                        />
                    </Box>
                );
            }
            if (status === "success") {
                return <CheckmarkTickIcon color="#fff" h="100%" w="auto" />;
            }
            return (
                <>
                    <Flex h="100%" w={buttonWidth ? buttonWidth : undefined}>
                        <GradientButton
                            label={submitButtonLabel}
                            onClick={(e) => handleSubmit(e)}
                            disabled={!validEmail}
                            buttonStyle={{
                                ...chakraTheme.textStyles.site.forms.button,
                                px: buttonPadding
                                    ? buttonPadding
                                    : chakraTheme.textStyles.site.forms.button.px,
                                fontSize: buttonFontSize
                                    ? buttonFontSize
                                    : chakraTheme.textStyles.site.forms.button.fontSize,
                            }}
                        />
                    </Flex>
                </>
            );
        };

        const StatusMessage = () => {
            if (status === "error" || status === "success") {
                return (
                    <Box
                        textStyle="site.forms.statusMessage"
                        fontSize={
                            statusFontSize
                                ? statusFontSize
                                : chakraTheme.textStyles.site.forms.statusMessage.fontSize
                        }
                    >
                        {status === "error"
                            ? message
                            : "To confirm, please click the link in the email we just sent you."}
                    </Box>
                );
            }

            return null;
        };

        return (
            <>
                <Flex
                    direction="column"
                    w="100%"
                    textAlign="center"
                    justifyContent="center"
                    alignItems="center"
                    gap="8px"
                >
                    <MotionBox
                        minW="100%"
                        bgGradient="linear(to-r, #F77A59 0%, #EA3A65 100%)"
                        rounded="50em"
                        p="2px"
                        initial={useMotion ? { opacity: 0, scale: 0.85 } : undefined}
                        whileInView={useMotion ? { opacity: 1, scale: 1 } : undefined}
                        transition={useMotion ? { duration: 0.5, ease: "easeOut" } : undefined}
                        viewport={{ once: true }}
                    >
                        <form onSubmit={(e) => handleSubmit(e)}>
                            <Flex
                                // size="md"
                                w="100%"
                                h={height}
                                transition="background .30s"
                                bgColor={status === "success" ? "sorbet" : "arsenic"}
                                // bgGradient={status === "success" ? "linear(to-r, #F77A59 0%, #EA3A65 100%)" : undefined}
                                rounded="50em"
                                justifyContent="center"
                                alignItems="center"
                                overflow="hidden"
                            >
                                <Input
                                    {...chakraTheme.textStyles.site.forms.value}
                                    fontSize={
                                        inputFontSize
                                            ? inputFontSize
                                            : chakraTheme.textStyles.site.forms.value.fontSize
                                    }
                                    disabled={status === "sending" || status === "success"}
                                    textAlign={status === "success" ? "center" : undefined}
                                    mr="-30px"
                                    pr="40px"
                                    pl="20px"
                                    variant="unstyled"
                                    value={status == "success" ? "" : email}
                                    transition="background .10s"
                                    onChange={(e) => setEmail(e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            handleSubmit(e);
                                        }
                                    }}
                                    placeholder={getPlaceholderText()}
                                    _placeholder={{
                                        ...chakraTheme.textStyles.site.forms.placeholder,
                                        color: `${getPlaceholderColor()}`,
                                    }}
                                    _focus={{ boxShadow: "none" }}
                                    _disabled={{ opacity: 1 }}
                                />
                                <Flex
                                    h="100%"
                                    borderWidth="0px"
                                    p="0px"
                                    m="0px"
                                    cursor="default"
                                    fontFamily="regular"
                                    justifyContent="stretch"
                                    alignItems="stretch"
                                    alignSelf="stretch"
                                >
                                    <FormSubmitButton />
                                </Flex>
                            </Flex>
                        </form>
                    </MotionBox>
                    <StatusMessage />
                </Flex>
            </>
        );
    };

    return (
        <MailchimpSubscribe
            url={postUrl}
            render={({ subscribe, status, message }) => (
                <CustomForm
                    status={status}
                    message={message}
                    onValidated={(formData) => subscribe(formData)}
                />
            )}
        />
    );
};

export default memo(SubscribeForm);
