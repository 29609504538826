import { Box, Flex, Text } from "@chakra-ui/react";
import HomeCollectionSearchBar from "@components/search/HomeCollectionSearchBar";
// import BannersSlider from "@components/site/BannersSlider";
// import BoxWithIcon from "@components/ui/BoxWithIcon";
import FeaturedProjectsSlider from "@components/site/projects/FeaturedProjectsSlider";
// import RecentlyAddedProjectsSlider from "@components/site/projects/RecentlyAddedProjectsSlider";
// import ResearchSlider from "@components/site/ResearchSlider";
// import SocialSection from "@components/ui/SocialSection";
// import { GetAccess } from "@components/site/GetAccess";
// import GradientButton from "@components/ui/GradientButton";
import { FullOnMobile, PaddedOnMobile } from "@components/site/layout";
import { useWindowSiteStore } from "@store/windowSiteStore";
import Head from "next/head";
import { GetServerSideProps } from "next";
import { getAllCollections } from "@apiSrc";
import ValuePropSection from "@components/site/home/ValuePropSection";
import SearchBackground from "@components/site/home/SearchBackground";
import VisionSection from "@components/site/home/VisionSection";
import StrongerSection from "@components/site/home/StrongerSection";
import SimplerSection from "@components/site/home/SimplerSection";
import EasierSection from "@components/site/home/EasierSection";
import ForYouSection from "@components/site/home/ForYouSection";
import TutorialsSection from "@components/site/home/TutorialsSection";
import TestimonialsSection from "@components/site/home/TestimonialsSection";
import PricingSection from "@components/site/home/PricingSection";
import { scale } from "@utils/helpers";
import ListCollectionSection from "@components/site/home/ListCollectionSection";
import ShareConnectSection from "@components/site/home/ShareConnectSection";
import SubscribeSection from "@components/site/home/SubscribeSection";
import { getServerSession } from "next-auth";
import { createAuthOptions } from "./api/auth/[...nextauth]";

export const getServerSideProps: GetServerSideProps = async ({ req, res }) => {
    // res.setHeader("Cache-Control", "public, s-maxage=60, stale-while-revalidate=600");
    const session = await getServerSession(req, res, createAuthOptions());

    let collections = null;

    try {
        const subdomain = req.headers?.host.split(/\./)[0];
        const isStage = subdomain.toLowerCase().endsWith("stage");
        collections = await getAllCollections(isStage);
    } catch (e) {
        console.log(e);
    }

    return {
        props: {
            collections: collections,
            session,
        },
    };
};

export const Home = ({ collections }) => {
    const isDesktop = useWindowSiteStore((state) => state.isDesktop);
    const windowWidth = useWindowSiteStore((state) => state.windowWidth);

    return (
        <>
            <Head>
                <title key="title">Beyond Rarity - Home</title>
            </Head>
            <Flex direction="column" w="100vw" gap={scale(windowWidth, 375, 1200, 100, 200) + "px"}>
                <SearchBackground>
                    <ValuePropSection />
                </SearchBackground>
                <FullOnMobile containerProps={{ px: isDesktop ? "50px" : "20px" }}>
                    <HomeCollectionSearchBar fallbackCollections={collections} />
                </FullOnMobile>
                <Flex direction="column" w="100%" gap={isDesktop ? 2 : 4}>
                    <Text textAlign="center" textStyle="site.head">
                        Project spotlight
                    </Text>
                    <FeaturedProjectsSlider />
                </Flex>
                <VisionSection />
                <StrongerSection />
                <SimplerSection />
                <EasierSection />
                <ForYouSection />
                <TutorialsSection />
                <TestimonialsSection />
                <Box>
                    <PricingSection />
                    <ListCollectionSection />
                    <ShareConnectSection />
                    <SubscribeSection mt={isDesktop ? "100px" : "80px"} />
                </Box>
            </Flex>
        </>
    );
};

export default Home;
