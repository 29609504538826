import { Box, Flex, Popover, PopoverProps, PopoverTrigger } from "@chakra-ui/react";
import { useMediaQuery } from "@chakra-ui/react";

export const ClickHoverPopover = ({ children, ...props }: PopoverProps) => {
    const [hasNoHover] = useMediaQuery(["any-hover: none"]);

    return (
        <Box
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            <Popover trigger={hasNoHover ? "click" : "hover"} {...props}>
                {children}
            </Popover>
        </Box>
    );
};

export const ClickHoverPopoverTrigger = ({ children, ...props }) => {
    return (
        <PopoverTrigger {...props}>
            <Flex cursor="pointer">
                {children}
            </Flex>
        </PopoverTrigger>
    );
};
