import { Box, Flex, Grid, GridItem, Icon, Image, Text, Tooltip, VStack } from "@chakra-ui/react";
import { LinkIcon } from "@components/icons";
import SocialCard from "@components/ui/SocialCard";
import { useWindowSiteStore } from "@store/windowSiteStore";
import { scale } from "@utils/helpers";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { FaDiscord, FaFacebook, FaHeart, FaTwitter } from "react-icons/fa";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { PaddedOnMobile } from "../layout";
import { motion } from "framer-motion";

const MotionGridItem = motion(GridItem);

const SHARE_LINK = "https://www.beyondrarity.com";
const TWITTER_LINK = "https://twitter.com/Beyond_Rarity";
const DISCORD_LINK = "https://discord.gg/beyondrarity";

type ShareConnectSectionProps = {
    shareURL?: string;
    shareCurrentUrl?: boolean;
};

export const ShareConnectSection = ({
    shareURL = SHARE_LINK,
    shareCurrentUrl = false,
}: ShareConnectSectionProps) => {
    const router = useRouter();
    const windowWidth = useWindowSiteStore((state) => state.windowWidth);
    const [fullUrl, setFullUrl] = useState<string>(shareURL);
    const [linkCopied, setLinkCopied] = useState(false);

    const portraitModeWidth = 860;
    const shareMessage =
        "I just found the best way to visualize NFT rarity and price estimates with ";

    useEffect(() => {
        if (shareCurrentUrl) {
            const protocol = window.location.protocol;
            const host = window.location.host;
            setFullUrl(`${protocol}${host}${router.pathname}`);
        }
    }, []);

    const handleCopyLinkClick = (e) => {
        // console.debug("full url: ", fullUrl);
        navigator.clipboard.writeText(fullUrl);
        setLinkCopied(true);
    };

    const scaleAnimation = (delay = 0, once = true) => ({
        initial: {
            scale: .8,
            opacity: 0,
        },
        whileInView: {
            scale: 1,
            opacity: 1,
        },
        transition: {
            duration: 0.8,
            ease: "easeOut",
            delay: delay,
        },
        viewport: {
            once: once,
        },
    });

    return (
        <>
            <PaddedOnMobile mt={"-0px"}>
                <Grid
                    templateColumns={windowWidth >= portraitModeWidth ? "1fr 1fr 1fr" : "auto"}
                    templateRows={windowWidth >= portraitModeWidth ? "auto" : "1fr 1fr 1fr"}
                    gap={windowWidth >= portraitModeWidth ? 8 : 20}
                >
                    <MotionGridItem {...scaleAnimation()}>
                        <SocialCard
                            icon={FaHeart}
                            name="Share the Love"
                            message="Excited about Beyond Rarity? Tell your friends & the world."
                        >
                            <Flex direction="column" fontSize="18px" fontWeight="light" gap={2}>
                                <Tooltip
                                    // isDisabled={!linkCopied.current}
                                    isOpen={linkCopied}
                                    placement="top"
                                    label={
                                        <Text px={2} fontWeight="light">
                                            Copied
                                        </Text>
                                    }
                                    onClose={() => {
                                        setLinkCopied(false);
                                    }}
                                >
                                    <Flex
                                        gap={2}
                                        onClick={handleCopyLinkClick}
                                        _hover={{ color: "sorbet", cursor: "pointer" }}
                                    >
                                        <Icon as={LinkIcon} w="20px" h="20px" />
                                        <Box>Copy link</Box>
                                    </Flex>
                                </Tooltip>
                                <FacebookShareButton
                                    url={fullUrl}
                                    quote={`${shareMessage}${"@BeyondRarityNFT"}`}
                                >
                                    <Flex gap={2} _hover={{ color: "sorbet", cursor: "pointer" }}>
                                        <Icon as={FaFacebook} w="20px" h="20px" />
                                        <Box>Share on Facebook</Box>
                                    </Flex>
                                </FacebookShareButton>
                                <TwitterShareButton
                                    url={fullUrl}
                                    title={`${shareMessage}${"@beyond_rarity"}`}
                                    related={["Beyond_Rarity"]}
                                >
                                    <Flex gap={2} _hover={{ color: "sorbet", cursor: "pointer" }}>
                                        <Icon as={FaTwitter} w="20px" h="20px" />
                                        <Box>Share on Twitter</Box>
                                    </Flex>
                                </TwitterShareButton>
                            </Flex>
                        </SocialCard>
                    </MotionGridItem>
                    <MotionGridItem {...scaleAnimation()}>
                        <SocialCard
                            icon={FaTwitter}
                            name="Twitter"
                            message="Follow us to stay on top of NFT news and valuation insights."
                            buttonLink={TWITTER_LINK}
                            buttonLabel="Follow Twitter"
                        />
                    </MotionGridItem>
                    <MotionGridItem {...scaleAnimation()}>
                        <SocialCard
                            icon={FaDiscord}
                            name="Discord"
                            message="Join our community for an inside scoop on cutting edge developments in the space."
                            buttonLink={DISCORD_LINK}
                            buttonLabel="Join Discord"
                        />
                    </MotionGridItem>
                </Grid>
            </PaddedOnMobile>
        </>
    );
};

export default ShareConnectSection;
