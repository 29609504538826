import { Box, Flex, Grid, GridItem, Icon, Image, VStack } from "@chakra-ui/react";
import { useWindowSiteStore } from "@store/windowSiteStore";
import { scale } from "@utils/helpers";
import { Fragment, useEffect, useState } from "react";
import { PaddedOnMobile } from "../layout";
import { motion } from "framer-motion";
import GradientButton from "@components/ui/GradientButton";

const MotionGridItem = motion(GridItem);
const MotionFlex = motion(Flex);
const MotionImage = motion(Image);

const ForYouSectionData = {
    label: "For You",
    labelColor: "emerald",
    title: "Personalized Experience",
    subtitle: "Beautiful gallery & analysis for your wallet",
    bullets: [
        {
            icon: "/icons/portfolio-view.svg",
            title: "Portfolio view",
            description: "Your gallery & price estimates in one place",
            comingSoon: false,
        },
        {
            icon: "/icons/wallet-estimate.svg",
            title: "Wallet Estimate",
            description: "Know your wallet's total token net worth",
            comingSoon: false,
        },
        {
            icon: "/icons/token-check.svg",
            title: "Token Check",
            description: "Stats & estimates of any token from your wallet that's listed on BR",
            comingSoon: false,
        },
    ],
};

type ForYouSectionProps = {};

export const ForYouSection = ({}: ForYouSectionProps) => {
    const isDesktop = useWindowSiteStore((state) => state.isDesktop);
    const windowWidth = useWindowSiteStore((state) => state.windowWidth);

    const minSize = isDesktop ? 960 : 375;
    const maxSize = isDesktop ? 1200 : 960;

    const profileImage = "/images/home/profile.png";

    const scaleAnimation = (delay = 0, once = true) => ({
        initial: {
            scale: 0.8,
            opacity: 0,
        },
        whileInView: {
            scale: 1,
            opacity: 1,
        },
        transition: {
            duration: 0.5,
            ease: "easeOut",
            delay: delay,
        },
        viewport: {
            once: once,
        },
    });

    const slideUpAnimation = (delay = 0, once = true) => ({
        initial: {
            translateY: "50px",
            opacity: 0,
        },
        whileInView: {
            translateY: "0px",
            opacity: 1,
        },
        transition: {
            duration: 0.8,
            ease: "easeOut",
            delay: delay,
        },
        viewport: {
            once: once,
        },
    });

    const slideLeftAnimation = (delay = 0, once = true) => ({
        initial: {
            translateX: "200px",
            opacity: 0,
        },
        whileInView: {
            translateX: "0px",
            opacity: 1,
        },
        transition: {
            duration: 0.8,
            ease: "easeOut",
            delay: delay,
        },
        viewport: {
            once: once,
        },
    });

    if (isDesktop) {
        return (
            <>
                <PaddedOnMobile>
                    <Grid w="100%" templateColumns="50% 1fr" gap={8}>
                        <GridItem>
                            <Flex h="100%" align="start">
                                <MotionImage src={profileImage} {...scaleAnimation()} />
                            </Flex>
                        </GridItem>
                        <GridItem>
                            <Flex direction="column" w="100%">
                                <Box
                                    fontFamily="regular"
                                    fontSize={scale(windowWidth, minSize, maxSize, 18, 24)}
                                    color={ForYouSectionData.labelColor}
                                >
                                    {ForYouSectionData.label}
                                </Box>
                                <Box
                                    fontFamily="regular"
                                    fontSize={scale(windowWidth, minSize, maxSize, 55, 72)}
                                    lineHeight="110%"
                                >
                                    {ForYouSectionData.title}
                                </Box>
                                <Box
                                    pt={2}
                                    pb={6}
                                    fontSize={scale(windowWidth, minSize, maxSize, 20, 24)}
                                    fontWeight="light"
                                >
                                    {ForYouSectionData.subtitle}
                                </Box>
                                <Grid
                                    w="100%"
                                    templateColumns="auto"
                                    templateRows="auto auto auto"
                                    rowGap={4}
                                >
                                    {ForYouSectionData.bullets.map((bullet, index) => {
                                        return (
                                            <Fragment key={index}>
                                                <MotionGridItem {...slideLeftAnimation(0.2)}>
                                                    <Grid templateColumns="auto 1fr" columnGap={3}>
                                                        <GridItem>
                                                            <Icon
                                                                as={Image}
                                                                src={bullet.icon}
                                                                w={
                                                                    scale(
                                                                        windowWidth,
                                                                        minSize,
                                                                        maxSize,
                                                                        70,
                                                                        85,
                                                                    ) + "px"
                                                                }
                                                                h={
                                                                    scale(
                                                                        windowWidth,
                                                                        minSize,
                                                                        maxSize,
                                                                        70,
                                                                        85,
                                                                    ) + "px"
                                                                }
                                                            />
                                                        </GridItem>
                                                        <GridItem>
                                                            <Flex
                                                                direction="column"
                                                                justify="center"
                                                                h="100%"
                                                                gap={1}
                                                            >
                                                                <Box
                                                                    fontFamily="regular"
                                                                    fontSize={scale(
                                                                        windowWidth,
                                                                        minSize,
                                                                        maxSize,
                                                                        24,
                                                                        30,
                                                                    )}
                                                                    lineHeight="110%"
                                                                >
                                                                    {bullet.title}
                                                                </Box>
                                                                <Box
                                                                    fontSize={scale(
                                                                        windowWidth,
                                                                        minSize,
                                                                        maxSize,
                                                                        18,
                                                                        24,
                                                                    )}
                                                                    fontWeight="light"
                                                                    color="lavenderGray"
                                                                    lineHeight="110%"
                                                                >
                                                                    {bullet.description}
                                                                </Box>
                                                                {bullet.comingSoon && (
                                                                    <Box
                                                                        fontFamily="regular"
                                                                        fontSize={scale(
                                                                            windowWidth,
                                                                            minSize,
                                                                            maxSize,
                                                                            10,
                                                                            15,
                                                                        )}
                                                                        fontWeight="medium"
                                                                        color="lavenderGray"
                                                                        lineHeight="110%"
                                                                    >
                                                                        Coming Soon
                                                                    </Box>
                                                                )}
                                                            </Flex>
                                                        </GridItem>
                                                    </Grid>
                                                </MotionGridItem>
                                            </Fragment>
                                        );
                                    })}
                                </Grid>
                                <MotionFlex
                                    w="100%"
                                    pt={8}
                                    justify="center"
                                    align="center"
                                    {...scaleAnimation(0.2)}
                                >
                                    <Box w="70%">
                                        <GradientButton link="/user/me" label="View Your Wallet" />
                                    </Box>
                                </MotionFlex>
                            </Flex>
                        </GridItem>
                    </Grid>
                </PaddedOnMobile>
            </>
        );
    }

    return (
        <>
            <PaddedOnMobile>
                <VStack gap={4} maxW="600px">
                    <Flex direction="column" maxW="95%">
                        <Box
                            fontFamily="regular"
                            fontSize={scale(windowWidth, minSize, maxSize, 12, 24)}
                            color={ForYouSectionData.labelColor}
                        >
                            {ForYouSectionData.label}
                        </Box>
                        <Box
                            fontFamily="regular"
                            fontSize={scale(windowWidth, minSize, maxSize, 32, 55)}
                            lineHeight="110%"
                        >
                            {ForYouSectionData.title}
                        </Box>
                        <Box
                            pt={2}
                            pb={4}
                            fontSize={scale(windowWidth, minSize, maxSize, 16, 24)}
                            fontWeight="light"
                        >
                            {ForYouSectionData.subtitle}
                        </Box>
                    </Flex>

                    <Flex w="100%" align="end">
                        <MotionImage src={profileImage} {...scaleAnimation()} />
                    </Flex>

                    <Grid
                        templateColumns="auto 1fr"
                        templateRows="auto auto auto"
                        columnGap={2}
                        rowGap={8}
                    >
                        {ForYouSectionData.bullets.map((bullet, index) => {
                            return (
                                <Fragment key={index}>
                                    <MotionGridItem {...slideUpAnimation(0.2)}>
                                        <Icon
                                            as={Image}
                                            src={bullet.icon}
                                            w={scale(windowWidth, minSize, maxSize, 60, 85) + "px"}
                                            h={scale(windowWidth, minSize, maxSize, 60, 85) + "px"}
                                        />
                                    </MotionGridItem>
                                    <MotionGridItem {...slideUpAnimation(0.2)}>
                                        <Flex direction="column" justify="center" h="100%" gap={1}>
                                            <Box
                                                fontFamily="regular"
                                                fontSize={scale(
                                                    windowWidth,
                                                    minSize,
                                                    maxSize,
                                                    18,
                                                    30,
                                                )}
                                                lineHeight="110%"
                                            >
                                                {bullet.title}
                                            </Box>
                                            <Box
                                                fontSize={scale(
                                                    windowWidth,
                                                    minSize,
                                                    maxSize,
                                                    16,
                                                    24,
                                                )}
                                                fontWeight="light"
                                                color="lavenderGray"
                                                lineHeight="110%"
                                            >
                                                {bullet.description}
                                            </Box>
                                            {bullet.comingSoon && (
                                                <Box
                                                    fontFamily="regular"
                                                    fontSize={scale(
                                                        windowWidth,
                                                        minSize,
                                                        maxSize,
                                                        10,
                                                        15,
                                                    )}
                                                    fontWeight="medium"
                                                    color="lavenderGray"
                                                    lineHeight="110%"
                                                >
                                                    Coming Soon
                                                </Box>
                                            )}
                                        </Flex>
                                    </MotionGridItem>
                                </Fragment>
                            );
                        })}
                    </Grid>
                    <MotionFlex
                        w="100%"
                        pt={8}
                        justify="center"
                        align="center"
                        {...scaleAnimation(0.2)}
                    >
                        <Box w="70%">
                            <GradientButton link="/user/me" label="View Your Wallet" />
                        </Box>
                    </MotionFlex>
                </VStack>
            </PaddedOnMobile>
        </>
    );
};

export default ForYouSection;
