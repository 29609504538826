import { Box } from "@chakra-ui/react";
import { useWindowSiteStore } from "@store/windowSiteStore";
import { scale } from "@utils/helpers";
import { ReactNode, useEffect, useState } from "react";
import { isDesktop } from "react-device-detect";

type SearchBackgroundProps = {
    children?: ReactNode;
};

export const SearchBackground = ({children}: SearchBackgroundProps) => {
    const isDesktop = useWindowSiteStore((state) => state.isDesktop);
    const windowWidth = useWindowSiteStore((state) => state.windowWidth);

    const height = Math.min(720, windowWidth);

    const imageUrl =
        windowWidth > 1100
            ? "/images/home/search-background-desktop.png"
            : "/images/home/search-background-mobile.png";

    return (
        <>
            <Box pos="relative" pt={isDesktop ?  0 : scale(windowWidth, 375, 960, 20, 100)+"px"} minW="100%" >
                <Box
                    pos="absolute"
                    top={isDesktop ? "-100px" : "0px"}
                    minW="100vw"
                    w="100vw"
                    h={height}
                    minH={height}
                    backgroundImage={`url('${imageUrl}')`}
                    backgroundRepeat="no-repeat"
                    backgroundPosition="top center"
                    bgSize="auto 100%"
                    zIndex={0}
                />
                {children}
            </Box>
        </>
    );
};

export default SearchBackground;
